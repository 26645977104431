import { html, preload } from "./utils/environment";
import device from "current-device";
import modular from 'modujs';
import * as modules from './modules';
import {StoreSingleton} from "./singletons/store";
import gsap from "gsap";

export default class App {
    constructor() {

        // DOM
        this.dom = {};

        // DETECT SAFARI
        if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {document.querySelector('html').classList.add('is-safari')}

        // CLASS MOBILE
        window.isMobile = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
        if (window.isMobile) {
            html.classList.add('is-mobile');
        }

        // MOBILE
        if(device.mobile() || window.innerWidth <= 1023)
            StoreSingleton.isMobile = true;

        // INIT
        this.init();
    }

    /**
     * INIT
     */
    init() {

        // Namespace
        window.deadwater = {
            app: null,
            scroll: {
                state: {}
            }
        };

        // MODULES
        window.deadwater.app = new modular({
            modules: modules
        });
        window.deadwater.app.init(window.deadwater.app);

        const _introduction = gsap.timeline({
            onComplete: () => {
                html.classList.remove('is-loading');
                preload.style.display = 'none';
            }
        });
        _introduction.to('#preload .l-2', {
            duration: 1.2,
            scaleY: 0,
            ease: 'power4.inOut'
        });
        _introduction.to('#preload .l-1', {
            duration: 1.2,
            scaleY: 0,
            ease: 'power4.inOut'
        }, 0.2);
        _introduction.call(() => {
            html.classList.add('is-loaded', 'is-ready', 'has-dom-ready');
        }, {}, 0.3);
    }
}

/**
 * ON LOAD
 * @private
 */
const __dcl = () => {
    new App();
};

document.addEventListener("DOMContentLoaded", __dcl);
