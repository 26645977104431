import { module } from "modujs";
import LocomotiveScroll from "locomotive-scroll";
import { html } from "../../utils/environment";

export default class extends module {
    constructor(m) {
        super(m);

        // FUNC
        this.update = this.update.bind(this);
        this._scrollToCallback = this._scrollToCallback.bind(this);
    }

    /**
     * Init
     * @returns {{top: number, left: number, width: number, height: number}|*}
     */
    init() {

        this.scroll = new LocomotiveScroll({
            el: this.el,
            smooth: true,
            offset: ['20%'],
            lerp: 0.15,
            firefoxMultiplier: 70,
            resetNativeScroll: false,
            reloadOnContextChange: true,
            tablet: {
                breakpoint: 1024
            }
        });

        // CALL
        this.scroll.on("call", (func, way, obj, id) => {
            this.call(func[0], { way, obj }, func[1], func[2]);
        });

        // SCROLL
        this.scroll.on("scroll", (args) => {
            window.deadwater.scroll.state = args;
        });
    }

    /**
     * Scroll to
     * @param i__target
     * @param i__options
     */
    scrollTo(i__args) {
        this.scroll.scrollTo(i__args[0], {
            duration: 300,
            callback: () => {
                this._scrollToCallback(i__args[2], i__args[3]);
            }
        });
    }

    /**
     * Update
     */
    update() {
        console.log("SCROLL -- UPDATE");
        this.scroll.update();
    }

    /**
     * Destroy
     */
    destroy() {
        console.log("SCROLL -- DESTROY");
        this.scroll.destroy();
    }

    _scrollToCallback(i__class, i__method) {
        console.log("CALLBACK");
        this.call(i__method, {}, i__class);
    }
}
