import { module } from 'modujs';
import gsap from "gsap";
import {SplitText} from "gsap/SplitText";
import {DrawSVGPlugin} from "gsap/DrawSVGPlugin";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(SplitText, DrawSVGPlugin, ScrollTrigger);

export default class extends module {
    constructor(m) {
        super(m);
        
        // VAR
        this.type = null;
    }

    /**
     * ANIMATION
     */
    init() {
        this.type = this.getData('type');

        // CHARS
        if(this.type === 'chars') {
            new SplitText(this.el, {type: 'lines, chars', charsClass: 'char char++', linesClass: 'line line++'});
        }

        // WORDS
        if(this.type === 'words') {
            let _s = new SplitText(this.el, {type: 'words', wordsClass: 'word word++'});

            for(let i = 0; i < _s.words.length; i++) {
                let __html = _s.words[i].innerHTML;
                let __div = document.createElement('div');
                __div.setAttribute('class', 'overflow-wrapper');
                __div.style.height = window.getComputedStyle(_s.words[i]).getPropertyValue('line-height');
                __div.innerHTML = __html;
                _s.words[i].innerHTML = '';
                _s.words[i].appendChild(__div);
            }
        }

        // DRAW
        if(this.type === 'draw') {
            gsap.set(this.el.querySelectorAll('path'), {
                drawSVG: '0% 0%',
            });
        }
    }
}
