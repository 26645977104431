import { module } from 'modujs';
import { html } from '../../utils/environment';
import gsap from "gsap";
import {findValueByPrefix} from "../../utils/utils";
import {StoreSingleton} from "../../singletons/store";

export default class extends module {
    constructor(m) {
        super(m);

        // FUNC
        this.siteIsReady = this.siteIsReady.bind(this);
    }

    init() {

        if(StoreSingleton.isPreload) {
            this.on('siteIsReady', 'Home', this.siteIsReady);
            this.on('siteIsReady', 'Common', this.siteIsReady);
        }

        if(!StoreSingleton.isPreload)
            this.startSite();
    }

    /**
     * HOME VIDEO READY
     */
    siteIsReady() {
        this.startSite();
    }

    /**
     * Start site
     */
    startSite() {
        document.querySelector('body').removeChild(document.querySelector('#preload'));



        const _modules = document.querySelector('[data-page]').dataset;
        const _pageClass = findValueByPrefix(_modules, 'module');
        window.deadwater.app.activeModules['Load']['Core'].call('start', {}, _pageClass)
    }
}
