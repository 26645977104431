import { module } from 'modujs';
import {StoreSingleton} from "../../../singletons/store";

export default class extends module {
    constructor(m) {
        super(m);

        // VAR
    }

    /**
     * INIT
     */
    init() {
        const _event = new Event('siteIsReady');
        this.el.dispatchEvent(_event);
    }

    /**
     * START
     */
    start() {}

    /**
     * DESTROY
     */
    destroy() {}
}
