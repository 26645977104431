import { module } from 'modujs';
import gsap from "gsap";

export default class extends module {
    constructor(m) {
        super(m);

        // VAR
        this.direction = 0;
        this.currentNumber = 0;
        this.currentText = this.$('text')[0];
        this.prevText = null;
        this.currentImage = this.$('image')[0];
        this.prevImage = null;
        this.timeline = null;
        this.isProcessing = false;

        // EVENT
        this.events = {
            click: {
                prev: '_slidePrev',
                next: '_slideNext'
            }
        }
    }

    init() {
        this.$('container')[0].style.height = (this.currentText.clientHeight + 40) + 'px';
    }

    /**
     * SLIDE
     */
    slide() {

        if(!this.isProcessing) {
            this.isProcessing = true;

            this.prevText = this.currentText;
            this.prevImage = this.currentImage;

            if(this.currentNumber + this.direction < 0)
                this.currentNumber = this.$('text').length - 1;
            else if(this.currentNumber + this.direction > this.$('text').length - 1)
                this.currentNumber = 0;
            else
                this.currentNumber = this.currentNumber + this.direction;

            this.currentText = this.$('text')[this.currentNumber];
            this.currentImage = this.$('image')[this.currentNumber];

            this.$('number')[0].innerHTML = ('0' + (this.currentNumber + 1)).slice(-2);
            this.$('container')[0].style.height = (this.currentText.clientHeight + 40) + 'px';

            this.timeline = gsap.timeline({
                onComplete: () => {
                    this.isProcessing = false;
                    this.timeline.clear();
                }
            });
            this.timeline.set(this.currentText, {
                xPercent: 10 * this.direction,
                autoAlpha: 0
            });
            this.timeline.set(this.currentImage, {
                xPercent: 10 * this.direction,
                autoAlpha: 0
            });
            this.timeline.to(this.prevText, {
                duration: 0.6,
                xPercent: -10 * this.direction,
                autoAlpha: 0,
                ease: 'power4.in'
            });
            this.timeline.to(this.prevImage, {
                duration: 0.6,
                xPercent: -10 * this.direction,
                autoAlpha: 0,
                ease: 'power4.in'
            }, 0.1);
            this.timeline.to(this.currentText, {
                duration: 0.6,
                xPercent: 0,
                autoAlpha: 1,
                ease: 'power4.out'
            }, 0.6);
            this.timeline.to(this.currentImage, {
                duration: 0.6,
                xPercent: 0,
                autoAlpha: 1,
                ease: 'power4.out'
            }, 0.7);
        }
    }

    /**
     * SLIDE PREV
     * @private
     */
    _slidePrev() {
        this.direction = -1;
        this.slide();
    }

    /**
     * SLIDE NEXT
     * @private
     */
    _slideNext() {
        this.direction = 1;
        this.slide();
    }
}
