import { module } from 'modujs';
import gsap from 'gsap';

export default class extends module {
    constructor(m) {
        super(m);

        // EVENTS
        this.events = {
            click: {
                open : 'open',
                close : 'close',
                innerClose : 'close',
            }
        }
    }

    init() {
    }

    open() {
        this.$('open')[0].style.display = 'none';
        this.$('close')[0].style.display = 'block';

        gsap.to(this.$('container')[0], {
            duration: 0.3,
            height: this.$('inner')[0].clientHeight,
            ease: 'power4.in0ut',
            onComplete: () => {
                this.$('container')[0].style.height = 'auto';
            }
        })
    }

    close() {
        this.$('open')[0].style.display = 'block';
        this.$('close')[0].style.display = 'none';

        gsap.to(this.$('container')[0], {
            duration: 0.3,
            height: 0,
            ease: 'power4.in0ut'
        })
    }
}
