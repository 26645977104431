import { module } from 'modujs';
import gsap from 'gsap';
import ScrollToPlugin from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export default class extends module {
    constructor(m) {
        super(m);

        // DOM
        this.dom = {};
        this.emails = document.querySelectorAll('.mailto');
        this.anchors = document.querySelectorAll('.go-to');
    }

    /**
     * INIT
     */
    init() {

        // EMAILS
        this.emails.forEach(item => {
            item.addEventListener('click', (event) => {
               event.preventDefault();
                window.location.href = 'mailto:' + item.dataset.email.replace('[at]', '@');
            });
        });

        // ANCHORS
        this.anchors.forEach(item => {
            item.addEventListener('click', (event) => {
                event.preventDefault();
                gsap.to(window, {
                    duration: 0.6,
                    scrollTo: {y:item.dataset.section, autoKill: false},
                    ease: 'power4.inOut'
                })
            });
        })
    }

    /**
     * START
     */
    start() {}

    /**
     * DESTROY
     */
    destroy() {}
}
